export const isValidEmailFormat = (email: string): boolean => {
  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/ // eslint-disable-line

  return emailRegex.test(email)
}

export const isValidBackupFetchRequest = (
  webhookRequest: WebhookRequest,
): boolean => {
  const { failureReason, responseBody, responseCode } = webhookRequest

  if (responseCode < 200 || responseCode >= 300) {
    return false
  }

  if (failureReason) {
    return false
  }

  try {
    const parsedResponseBody = JSON.parse(responseBody) as {
      backupShare?: string
      backupShares?: string[]
    }
    if (!parsedResponseBody?.backupShare && !parsedResponseBody?.backupShares) {
      return false
    }
  } catch (error) {
    return false
  }

  return true
}

export const isValidBackupRequest = (
  webhookRequest: WebhookRequest,
): boolean => {
  const { failureReason, responseCode } = webhookRequest

  if (responseCode < 200 || responseCode >= 300) {
    return false
  }

  if (failureReason) {
    return false
  }

  return true
}

export const isValidTotpCodeFormat = (code: string): boolean => {
  return code.length === 6
}

export const isValidSlugFormat = (slug: string): boolean => {
  return slug.length > 0
}
