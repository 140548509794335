import { Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { hexToCSSFilter } from 'hex-to-css-filter'
import { rgba } from 'polished'

import { hexToCSSConfig } from '@lib/colors'

const buttonStyles: ButtonAttributes = {
  alignContent: 'center',
  alignItems: 'center',
  borderWidth: '1px',
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: '14px',
  transition: 'all 0.2s ease-in-out',

  ':disabled': {
    opacity: 0.5,
  },
}

export const ButtonIcon = styled.img({
  display: 'inline-block',
})

const buildButtonSizeStyles = (size: ValidButtonSizes): ButtonAttributes => {
  if (['sm', 'small'].includes(size)) {
    // Small button sizes
    return {
      borderRadius: '4px',
      height: '28px',
      padding: '4px 6px',
      [`${ButtonIcon}`]: {
        maxWidth: '12px',
        minWidth: '12px',
      },
    }
  } else if (['lg', 'large'].includes(size)) {
    // Large button sizes
    return {
      borderRadius: '10px',
      height: '45px',
      padding: '12px 16px',
      [`${ButtonIcon}`]: {
        maxWidth: '16px',
        minWidth: '16px',
      },
    }
  } else {
    // Default/medium button sizes
    return {
      borderRadius: '10px',
      height: '34px',
      padding: '8px 10px',
      [`${ButtonIcon}`]: {
        maxWidth: '12px',
        minWidth: '12px',
      },
    }
  }
}

const buildBorderedButtonColorStyles = (
  theme: Theme,
  color: ValidButtonColors = 'primary',
): ButtonAttributes => {
  const styles: ButtonAttributes = {}
  const themeColor = theme.colors[color]

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  styles.backgroundColor = 'transparent'
  styles.borderColor = themeColor
  styles.color = themeColor

  styles['&:hover'] = {
    backgroundColor: rgba(themeColor, 0.05),
  }
  /* eslint-enable */

  return styles
}

const buildMinimalButtonColorStyles = (
  theme: Theme,
  color: ValidButtonColors = 'primary',
): ButtonAttributes => {
  const styles: ButtonAttributes = {}
  const themeColor = theme.colors[color]

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  styles.backgroundColor = 'transparent'
  styles.borderColor = 'transparent'
  styles.color = themeColor

  styles['&:hover'] = {
    backgroundColor: rgba(themeColor, 0.05),
  }
  /* eslint-enable */

  return styles
}

const buildSolidButtonColorStyles = (
  theme: Theme,
  color: ValidButtonColors = 'primary',
): ButtonAttributes => {
  const styles: ButtonAttributes = {}
  const themeColor = theme.colors[color]
  const textColor =
    color === 'primary' ? theme.colors.white : theme.colors.black

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  styles.backgroundColor = themeColor
  styles.color = textColor

  if (theme.isDark) {
    styles.backgroundColor = theme.colors.blue
    styles.borderColor = theme.colors.blue
  }
  /* eslint-enable */

  return styles
}

const buildButtonColorStyles = (
  theme: Theme,
  color: ValidButtonColors = 'primary',
  style: ValidButtonStyles = 'bordered',
): ButtonAttributes => {
  switch (style) {
    case 'bordered':
      return buildBorderedButtonColorStyles(theme, color)
    case 'minimal':
      return buildMinimalButtonColorStyles(theme, color)
    case 'solid':
      return buildSolidButtonColorStyles(theme, color)
  }
}

/* eslint-disable */
export const Button = styled.button<ButtonProps>(
  {
    ...buttonStyles,
  },
  ({
    buttonStyle = 'bordered',
    color = 'primary',
    iconPosition = 'right',
    size = 'default',
    theme,
  }) => ({
    ...buildButtonColorStyles(theme, color, buttonStyle),
    ...buildButtonSizeStyles(size),
    [`${ButtonIcon}`]: {
      filter: hexToCSSFilter(theme.colors[color], hexToCSSConfig).filter,
      marginLeft: iconPosition === 'right' ? '.5rem' : 0,
      marginRight: iconPosition === 'left' ? '.5rem' : 0,
    },
    ':disabled': {
      backgroundColor: theme.isDark ? null : theme.colors.text.body,
      color: theme.isDark ? theme.colors.text.body : null,
    },
  }),
)
/* eslint-enable */
