import { useStytchB2BClient } from '@stytch/nextjs/b2b'
import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
} from 'react'

import { Button } from '@components/core/buttons'
import { Card } from '@components/core/card'
import { FormGroup } from '@components/core/forms'
import { PortalIcon } from '@components/core/icons'
import Spinner from '@components/core/spinner'
import api from '@lib/api'
import { isValidEmailFormat, isValidSlugFormat } from '@lib/validate'

import {
  EmailSentText,
  Label,
  LoginAcknowledgementContainer,
  LoginBackground,
  LoginContainer,
  LoginFormWrapper,
  LoginHeading,
  LoginMethodButton,
  LoginSelectionContainer,
  LoginSubmissionContainer,
  Notice,
  StyledInput,
  TextButton,
  ThematicBreak,
} from './style'

const Login: FunctionComponent = () => {
  const stytch = useStytchB2BClient()
  const magicLinkFormRef = useRef<HTMLFormElement>()
  const emailRef = useRef<HTMLInputElement>()
  const ssoFormRef = useRef<HTMLFormElement>()
  const custodianSlugRef = useRef<HTMLInputElement>()

  const [loginStep, setLoginStep] = useState<
    'selection' | 'submission' | 'acknowledgement'
  >('selection')
  const [loginMethod, setLoginMethod] = useState<
    'googleOauth' | 'msOauth' | 'magiclink' | 'sso'
  >()

  // magiclink
  const [email, setEmail] = useState<string>('')
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [emailSending, setEmailSending] = useState<boolean>(false)
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false)

  // sso
  const [custodianSlug, setCustodianSlug] = useState<string>('')
  const [ssoInitiating, setSsoInitiating] = useState<boolean>(false)
  const [isValidCustodianSlug, setIsValidCustodianSlug] =
    useState<boolean>(false)

  const handleEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value } = event.target
    const isValidEmail = isValidEmailFormat(value)

    setEmail(value)
    setIsValidEmail(isValidEmail)
    emailRef.current.setCustomValidity('')

    validateMagicLinkForm()
  }

  const handleEmailMagicLink = useCallback(
    async (event: FormEvent): Promise<void> => {
      event.preventDefault()
      setEmailSent(false)
      setEmailSending(true)

      if (magicLinkFormRef.current.checkValidity()) {
        try {
          await api.auth.magicLink({
            email,
          })

          setEmailSent(true)
          setLoginStep('acknowledgement')
        } catch (error) {
          emailRef.current.setCustomValidity((error as Error).message)
          emailRef.current.reportValidity()

          validateMagicLinkForm()
        }
      }

      setEmailSending(false)
    },
    [email, stytch],
  )

  const handleGoogleOAuth = useCallback(async () => {
    const { host, protocol } = window.location

    await stytch.oauth.google.discovery.start({
      discovery_redirect_url: `${protocol}//${host}/auth/verify`,
    })
  }, [stytch])

  const handleMSOAuth = useCallback(async () => {
    const { host, protocol } = window.location

    await stytch.oauth.microsoft.discovery.start({
      discovery_redirect_url: `${protocol}//${host}/auth/verify`,
    })
  }, [stytch])

  const handleCustodianSlugChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newSlug = event.target.value

      setCustodianSlug(newSlug)
      custodianSlugRef.current.setCustomValidity(
        isValidSlugFormat(newSlug) ? '' : 'Invalid slug',
      )

      validateSsoForm()
    },
    [],
  )

  const handleSso = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      if (ssoInitiating) return
      setSsoInitiating(true)

      const { host, protocol } = window.location

      try {
        const data = await api.auth.validateSso({ custodianSlug })

        await stytch.sso.start({
          connection_id: data.stytchSsoConnectionId,
          login_redirect_url: `${protocol}//${host}/auth/verify`,
          signup_redirect_url: `${protocol}//${host}/auth/verify`,
        })
      } catch (error) {
        let errorMessage = (error as Error).message
        if (errorMessage === 'Custodian does not exist.') {
          errorMessage = 'Could not find organization with that slug.'
        }

        custodianSlugRef.current.setCustomValidity(errorMessage)
        custodianSlugRef.current.reportValidity()

        validateSsoForm()
      } finally {
        setSsoInitiating(false)
      }
    },
    [stytch, custodianSlug, ssoInitiating, custodianSlugRef],
  )

  const validateMagicLinkForm = (): void => {
    const isValid = magicLinkFormRef.current.checkValidity()

    setIsValidEmail(isValid)
  }

  const validateSsoForm = (): void => {
    const isValid = ssoFormRef.current.checkValidity()

    setIsValidCustodianSlug(isValid)
  }

  const clearInputs = (): void => {
    setEmail('')
    setCustodianSlug('')
  }

  return (
    <>
      <LoginContainer>
        <Card
          color="white"
          style={{
            boxShadow: `200px -80px 150px rgba(0, 123, 255, 0.1), -80px 150px 150px rgba(70, 130, 180, 0.1), -20px -20px 70px rgba(0, 0, 255, 0.15), 20px 20px 70px rgba(100, 149, 237, 0.15)`,
            margin: 'auto',
            padding: '2.5rem',
          }}
        >
          <LoginHeading>
            <PortalIcon id="portal-logo" />
          </LoginHeading>

          {loginStep === 'selection' && (
            <LoginSelectionContainer>
              <LoginMethodButton
                buttonStyle="solid"
                id="google-oauth"
                onClick={handleGoogleOAuth}
                size="large"
              >
                <span>Continue with Google</span>
              </LoginMethodButton>

              <LoginMethodButton
                buttonStyle="bordered"
                id="ms-oauth"
                onClick={handleMSOAuth}
                size="large"
              >
                <span>Continue with Microsoft</span>
              </LoginMethodButton>

              <LoginMethodButton
                buttonStyle="bordered"
                id="magiclink-auth-init"
                size="large"
                onClick={() => {
                  setLoginMethod('magiclink')
                  setLoginStep('submission')
                }}
              >
                <span>Continue with email</span>
              </LoginMethodButton>

              <LoginMethodButton
                buttonStyle="bordered"
                id="sso-auth-init"
                size="large"
                onClick={() => {
                  setLoginMethod('sso')
                  setLoginStep('submission')
                }}
              >
                <span>Continue with SSO</span>
              </LoginMethodButton>
            </LoginSelectionContainer>
          )}

          {loginStep === 'submission' && (
            <LoginSubmissionContainer>
              {loginMethod === 'magiclink' && (
                <>
                  <LoginFormWrapper
                    onSubmit={handleEmailMagicLink}
                    ref={magicLinkFormRef}
                  >
                    <FormGroup>
                      <Label>What&apos;s your email?</Label>
                      <StyledInput
                        autoFocus
                        id="email"
                        ref={emailRef}
                        onChange={handleEmailChange}
                        placeholder="email@example.com"
                        required
                        type="email"
                      />
                    </FormGroup>

                    <Button
                      buttonStyle="solid"
                      disabled={!isValidEmail}
                      id="email-magic-link"
                      size="large"
                      style={{
                        width: '100%',
                      }}
                      type="submit"
                    >
                      <span>
                        {emailSending ? (
                          <Spinner color="white" />
                        ) : (
                          'Email magic link'
                        )}
                      </span>
                    </Button>
                  </LoginFormWrapper>
                </>
              )}

              {loginMethod === 'sso' && (
                <>
                  <LoginFormWrapper ref={ssoFormRef} onSubmit={handleSso}>
                    <FormGroup>
                      <Label>What&apos;s your organization&apos;s slug?</Label>
                      <StyledInput
                        id="slug"
                        ref={custodianSlugRef}
                        onChange={handleCustodianSlugChange}
                        placeholder="portalhq"
                        required
                        autoFocus
                      />
                    </FormGroup>
                    <span style={{ padding: '16px 0 0 0', fontSize: '12px' }}>
                      Your organization&apos;s slug is present in the Settings
                      page under the Single Sign-On section
                    </span>
                    <Button
                      buttonStyle="solid"
                      disabled={!isValidCustodianSlug}
                      id="slug-submission"
                      size="large"
                      style={{
                        width: '100%',
                      }}
                      type="submit"
                    >
                      <span>
                        {ssoInitiating ? <Spinner color="white" /> : 'Continue'}
                      </span>
                    </Button>
                  </LoginFormWrapper>
                </>
              )}

              <TextButton
                id="auth-submission-back"
                style={{
                  textAlign: 'center',
                  fontSize: '12px',
                  marginTop: '2rem',
                }}
                onClick={() => {
                  setLoginStep('selection')
                  clearInputs()
                }}
              >
                Back
              </TextButton>
            </LoginSubmissionContainer>
          )}

          {loginStep === 'acknowledgement' &&
            loginMethod === 'magiclink' &&
            emailSent && (
              <LoginAcknowledgementContainer>
                <Label id="magic-link-sent-label">Check your email</Label>
                <span>
                  We&apos;ve sent a temporary login link. Please check your
                  inbox at <strong>{email}</strong>.
                </span>
                <TextButton
                  id="auth-acknowledgement-back"
                  style={{
                    textAlign: 'center',
                    fontSize: '12px',
                    marginTop: '2rem',
                  }}
                  onClick={() => {
                    setLoginStep('submission')
                  }}
                >
                  Back
                </TextButton>
              </LoginAcknowledgementContainer>
            )}

          <Notice id="terms-conditions">
            By signing in, you accept{' '}
            <a
              href="https://www.portalhq.io/customer-terms-conditions"
              rel="noreferrer noopener"
              target="_blank"
            >
              Portal Labs, Inc&apos;s Terms & Conditions
            </a>
            .
          </Notice>
        </Card>
      </LoginContainer>
      <LoginBackground />
    </>
  )
}

export default Login
