import styled from '@emotion/styled'

type ValidCardColors = 'black' | 'white'

interface CardProps {
  color?: ValidCardColors
}

export const Card = styled.div<CardProps>(
  {
    borderRadius: 'calc((1rem / 3) * 4)',
    display: 'flex',
    flexDirection: 'column',
    padding: 'calc((1rem / 3) * 4)',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  ({ color = 'black', theme }) => ({
    background: theme.isDark ? theme.colors.black : theme.colors[color],
    color: theme.colors.text.body,
  }),
)
