import { HexToCssConfiguration } from 'hex-to-css-filter'

export const hexToRGB = (hex: string): number[] => {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
  if (normal) return normal.slice(1).map((e) => parseInt(e, 16))

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i)
  if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16))

  return null
}

export const hexToCSSConfig: HexToCssConfiguration = {
  acceptanceLossPercentage: 0,
  maxChecks: 50,
}
