import styled from '@emotion/styled'

import { mediaQueries } from '@lib/mediaQueries'

export const FieldLabel = styled.label(
  {
    display: 'block',
    letterSpacing: '.5px',
    marginBottom: '1rem',
    width: '100%',
  },
  ({ theme }) => ({
    color: theme.colors.text.body,
  }),
)

export const FieldError = styled.p({}, ({ theme }) => ({
  color: theme.colors.error,
  fontSize: '0.75rem',
  margin: '0 0 0.5rem',
}))

export const FormGroup = styled.div<FormGroupProps>(
  {
    display: 'flex',
    flexWrap: 'wrap',
    '&:not(:first-of-type)': {
      marginTop: '1rem',
    },
  },
  ({ width = '100%' }) =>
    mediaQueries({
      width,
    }),
)

export const FormWrapper = styled.form({
  '&:not(:first-of-type)': {
    marginTop: '1rem',
  },
})
