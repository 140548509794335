import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { radialGradient } from 'polished'

import { Button } from '@components/core/buttons'
import { Input } from '@components/core/forms/inputs'
import { mediaQueries } from '@lib/mediaQueries'

export const LoginHeading = styled.div(({ theme }) => ({
  color: theme.colors.white,
  textAlign: 'center',

  svg: {
    width: 150,

    g: {
      fill: theme.isDark ? theme.colors.white : null,
    },
  },
}))

export const LoginBackground = styled.div({
  bottom: 0,
  height: '100vh',
  maxHeight: '100vh',
  overflowY: 'hidden',
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  width: '100vw',

  '&:after': {
    ...radialGradient({
      colorStops: [
        '100.75% 50% at 38.61% 50%',
        '#608CFF 0%',
        ' rgba(169, 193, 255, 0) 99.98%',
        'rgba(220, 211, 255, 0) 99.99%',
      ],
    }),
    content: '""',
    display: 'block',
    filter: 'blur(150px)',
    height: '1211.87px',
    position: 'absolute',
    top: '50%',
    transform: 'rotate(49.91deg) translateY(-70%)',
    width: '311.14px',
    zIndex: 0,
  },

  '@media (max-width: 767px)': {
    overflow: 'hidden',
  },
})

export const LoginContainer = styled.div(
  mediaQueries({
    alignContent: 'center',
    display: 'flex',
    height: '100vh',
    margin: 'auto',
    maxWidth: '400px',
    width: '100%',

    [`${Button}`]: {
      width: '100%',
    },

    '@media (max-width: 767px)': {
      margin: '0 20px',
      maxWidth: '100%',
      width: 'auto',
    },
  }),
)

export const Label = styled.label(
  {
    fontSize: '0.875rem',
    margin: '10px 0',
  },
  ({ theme }) => ({
    color: theme.colors.text.body,
  }),
)

export const StyledInput = styled(Input)(
  {
    transition: 'all 0.3s ease-in-out',

    '&:focus': {
      border: '1px solid transparent',
    },
  },
  ({ theme }) => ({
    '&:focus': {
      outline: `1px solid ${theme.colors.primary}`,

      '&:invalid': {
        outline: `1px solid ${theme.colors.error}`,
      },
    },
  }),
)

export const LoginFormWrapper = styled.form({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',

  [`${Button}`]: {
    marginTop: '1.5rem',
    width: '100%',

    span: {
      textAlign: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
    },
  },

  [`${Label}`]: {
    width: '100%',
    textAlign: 'center',
  },
})

export const EmailSentText = styled.p(
  {
    fontSize: '0.875rem',
    margin: '2rem 0 0',
  },
  ({ theme }) => ({
    color: theme.colors.primary,
  }),
)

export const ThematicBreak = styled.hr(
  {
    border: 'none',
    height: '1px',
    margin: '2rem 0 0',
    width: '50px',
  },
  ({ theme }) => ({
    backgroundColor: theme.colors.greyLight,
  }),
)

export const Notice = styled.p(
  {
    fontSize: '0.7rem',
    textAlign: 'center',

    a: {
      textDecoration: 'none',
    },
  },
  ({ theme }) => ({
    color: theme.colors.text.body,

    a: {
      color: theme.colors.primary,
    },
  }),
)

export const TextButton = styled.span(
  {
    cursor: 'pointer',
  },
  ({ theme }) => ({
    color: theme.colors.primary,
  }),
)

export const LoginMethodButton = styled(Button)({
  margin: '6px 0',
  width: '100%',

  span: {
    width: '100%',
    textAlign: 'center',
    letterSpacing: '0.025rem',
    fontWeight: 500,
  },
})

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const LoginSelectionContainer = styled.div({
  animation: `${fadeIn} 0.3s ease-in-out`,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  margin: '2rem 0 2rem 0',
})

export const LoginSubmissionContainer = styled.div({
  animation: `${fadeIn} 0.3s ease-in-out`,
  display: 'flex',
  flexDirection: 'column',
  margin: '2rem 0 2rem 0',
  width: '100%',

  span: {
    width: '100%',
    textAlign: 'center',
  },
})

export const LoginAcknowledgementContainer = styled.div({
  animation: `${fadeIn} 0.3s ease-in-out`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '2rem 0 2rem 0',
  width: '100%',

  [`${Label}`]: {
    fontWeight: '550',
  },

  span: {
    width: '100%',
    textAlign: 'center',
  },
})
