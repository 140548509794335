import { NextPage } from 'next'
import Head from 'next/head'
import { useEffect } from 'react'

import LoginComponent from '@components/login'
import Page from '@components/page'

const LoginPage: NextPage = () => {
  useEffect(() => {
    analytics.page('Login')
  }, [])

  return (
    <Page>
      <Head>
        <title>Portal | Login</title>
      </Head>

      <LoginComponent />
    </Page>
  )
}

export default LoginPage
